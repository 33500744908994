async function init() {
  const collapseList = new Map();

  const allButtonsCollapse = document.querySelectorAll(
    '[data-bs-toggle="collapse"]'
  );

  allButtonsCollapse.forEach((el) => {
    el.addEventListener("click", async () => {
      const { default: _Collapse } = await import("bootstrap/js/src/collapse");

      if (!collapseList.has(el.getAttribute("data-bs-target")))
        collapseList.set(el.getAttribute("data-bs-target"),
          new _Collapse(el.getAttribute("data-bs-target"))
        );
      else
        collapseList.get(el.getAttribute("data-bs-target")).toggle();
    });
  });
}

export default {
  init,
};
