function handlePhotowipe(itensGaleria, gid, indexStart, pid) {
  if (itensGaleria.length) {
    let gallery;
    const itensPhotowipe = [];

    itensGaleria.forEach((el) => {
      const w = el.getAttribute("data-width");
      const h = el.getAttribute("data-height");

      const options = {
        w,
        h,
        pid,
      };

      //video
      if (el.hasAttribute("data-flag-video"))
        options[
          "html"
        ] = `<div class="container-video"><div class="wrapper-video"><div class="ratio ratio-16x9"><iframe src data-src="${el.getAttribute(
          "href"
        )}" frameborder="0" allowfullscreen></iframe></div></div></div>`;
      else options["src"] = el.getAttribute("href");

      if ((!!options["html"] || !!options["src"]) && !!w && !!h) {
        itensPhotowipe.push(options);
      }
    });

    if (itensGaleria.length) {
      const pswpElement = document.querySelectorAll(".pswp")[0];

      // define options (if needed)
      var options = {
        galleryUID: gid,
        index: indexStart, // start at first slide
        history: false,
        shareButtons: [
          {
            id: "whatsapp",
            label: "Whatsapp",
            url: "https://web.whatsapp.com/send?text={{text}} - {{raw_image_url}}",
          },
          {
            id: "facebook",
            label: "Facebook",
            url: "https://www.facebook.com/sharer/sharer.php?u={{raw_image_url}}",
          },
          {
            id: "twitter",
            label: "Tweet",
            url: "https://twitter.com/intent/tweet?text={{text}}&url={{raw_image_url}}",
          },
        ],
        getImageURLForShare: function (shareButtonData) {
          if (gallery) {
            if (gallery.currItem.src) {
              return gallery.currItem.src;
            } else if (gallery.currItem.html) {
              let elHtml = document.createElement("div");
              elHtml.innerHTML = gallery.currItem.html;

              if (shareButtonData.id === "download") {
                shareButtonData.label = "Download vídeo";
              }

              let el_target = elHtml.querySelector("[src]");
              return el_target.src || "";
            }
          }
          return "";
        },
        getTextForShare: function (shareButtonData) {
          return gallery.currItem.title || document.title;
        },
      };

      if (!window._mochileiros.statusPhotoSwipe) {
        window._mochileiros.statusPhotoSwipe = "processing";

        // import 'photoswipe/dist/photoswipe'
        // import 'photoswipe/dist/photoswipe-ui-default'

        const inicializePhotoSwipe = async () => {
          const { default: _cssPhotoSwipe } = await import(
            "../../scss/plugins/photoswipe-style.scss"
          );
          const { default: _PhotoSwipe } = await import("photoswipe");
          const { default: _PhotoSwipeUI_Default } = await import(
            "photoswipe/src/js/ui/photoswipe-ui-default"
          );

          // Initializes and opens PhotoSwipe
          gallery = new _PhotoSwipe(
            pswpElement,
            _PhotoSwipeUI_Default,
            itensPhotowipe,
            options
          );

          // Gallery starts closing
          gallery.listen("close", function () {
            if (!!gallery.currItem.html)
              document.querySelectorAll(".pswp iframe").forEach((i) => {
                i.removeAttribute("src");
              });
          });

          // Before slides change
          // (before the content is changed, but after navigation)
          // Update UI here (like "1 of X" indicator)
          gallery.listen("beforeChange", function () {
            if (!!gallery.currItem.html)
              document.querySelectorAll(".pswp iframe").forEach((i) => {
                i.removeAttribute("src");
              });
          });

          // After slides change
          // (after content changed)
          gallery.listen("afterChange", function () {
            if (!!gallery.currItem.html)
              document.querySelectorAll(".pswp iframe").forEach((i) => {
                i.src = i.getAttribute("data-src");
              });
          });

          gallery.init();

          window._mochileiros.statusPhotoSwipe = null;
        };

        inicializePhotoSwipe();
      }
    }
  }
}

async function init() {
  const allGaleriaItens = document.querySelectorAll(".galeria-swipe");

  if (allGaleriaItens.length) {
    allGaleriaItens.forEach((el) => {
      el.addEventListener("click", (event) => {
        event.preventDefault();

        let gid = el.getAttribute("data-gid");
        let indexImg = parseInt(el.getAttribute("data-index"));
        let nomeGaleria = el.getAttribute("data-galeria");
        let itensGaleria = document.querySelectorAll(
          '[data-gid="' + gid + '"]'
        );

        handlePhotowipe(itensGaleria, gid, indexImg, nomeGaleria);
      });
    });
  }
}

export default {
  init,
};
