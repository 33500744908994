// "use strict";

import AOS from "aos";
import { jarallax } from "jarallax";
import scrollPage from "./partials/controller_scrollPage";
import photoswipe from "./partials/controller_photoswipe";
import collapse from "./partials/controller_collapse";
import contact from './partials/controller_contact'
import donate from './partials/controller_donate'

(() => {
  window.addEventListener("DOMContentLoaded", () => {
    scrollPage.init();
  });

  window.addEventListener("load", () => {
    // kick off the AOS
    AOS.init({}); // once: true
    // kick off the jarallax
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
    // kick off the photo swipe
    photoswipe.init();
    // kick off the collapse bootstrap
    collapse.init();
    // kick off the contact scripts
    contact.init();
    // kick off the donate scripts
    donate.init();
    // handle click poster iframe
    window.onHandleClickPosterIframe = function (e) {
      e.target.outerHTML = `
        <iframe src="${e.target.getAttribute('data-youtube')}?autoplay=1&start=0" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>`;
    }
  });
})();