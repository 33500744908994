import { debounce, mobileCheck } from "../utils";

const handleScroll = async () => {
  if (window.scrollY > window.innerHeight / 2) {
    document.body.classList.add("scroll-down");
  } else {
    document.body.classList.remove("scroll-down");
  }
};

function init() {
  if (!mobileCheck() || window.innerWidth > 768) {
    handleScroll();
    window.addEventListener("scroll", debounce(handleScroll, 400));
  }

  window._mochileiros.goTo = (pointerTop) => {
    window.scroll({
      top: pointerTop,
      left: 0,
      behavior: "smooth",
    });
  };

  const allLinksScroll = document.querySelectorAll("[data-scroll]");

  allLinksScroll.forEach((el) => {
    el.addEventListener("click", (event) => {
      event.preventDefault();

      const elTarget = document.querySelector(
        `#${el.getAttribute("href").split("#")[1]}`
      );

      if (elTarget) {
        window._mochileiros.goTo(+elTarget.offsetTop - 60);
        document.querySelector("#openSidebarMenu").checked = false;
      }
    });
  });
}

export default {
  init,
};
