function init() {
  window.handleSubmitFormContact = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const btnSubmit = event.target.querySelector('button[type="submit"]');
    btnSubmit.disabled = true;

    const { default: Swal } = await import("sweetalert2");

    const formData = new FormData(event.target);

    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");

    fetch(`/contato`, {
      method: "post",
      headers: myHeaders,
      body: formData,
    })
      .then((response) => {
        console.log(response);

        if (response.ok) {
          return new Promise((resolve) => {
            resolve({});
          });
        } else if (response.status === 422) return response.json();
      })
      .then((response_data) => {
        if (response_data.message) {
          Swal.fire({
            title: "Ops...",
            text: response_data.message,
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
          });
          return;
        }

        event.target.querySelectorAll(".form-control").forEach((element) => {
          element.value = "";
        });

        Swal.fire({
          showCloseButton: true,
          showConfirmButton: false,
          icon: "success",
          title: "Mensagem Enviada!",
          text: "Recebemos sua mensagem. Aguarde, retornaremos o mais breve possível ;)",
        });

      })
      .catch((err) => {
        Swal.fire({
          showCloseButton: true,
          showConfirmButton: false,
          title: "Ops...",
          text: "Não conseguimos enviar sua mensagem. Por favor, tente novamente.",
          icon: "error",
        });
      })
      .finally(() => {
        btnSubmit.disabled = false;
      });
  };
}

export default {
  init,
}