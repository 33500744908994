function init() {
    window._copyClipBoard = async function (event, tipo, alert = true) {

        if (!event.currentTarget.getAttribute("data-target"))
            return

        const elTarget = document.getElementById(event.currentTarget.getAttribute("data-target"));

        if (elTarget) {
            elTarget.select();
            elTarget.setSelectionRange(0, 99999);

            navigator.clipboard.writeText(elTarget.value.trim());

            if (alert) {
                const { default: Swal } = await import("sweetalert2");

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    },
                    customClass: {
                        popup: 'alert-success',
                    }
                })

                Toast.fire({
                    html: tipo + ' copiada com sucesso!'
                })
            }


        }

    }


    window._openModalDonate = async function (event) {
        const { default: Swal } = await import("sweetalert2");

        Swal.fire({
            title: '<strong>Compartilhar QR Code PIX</strong>',
            html:
                `
                <img width="220" src="static/img/qrcode-pix.jpeg" alt=""
                    class="img-fluid d-block" style="margin: 0 auto" />

                <a class="btn" href="download?url=static/img/qrcode-pix.jpeg" target="_blank" rel="noopener noreferrer" style="display: inline-block;margin: .5rem 0;outline: none !important;border: 1px solid #CCC;border-radius: 2rem;padding: .6rem 1.5rem;font-weight: 600;color: #444;font-size: .95rem;box-shadow: 0 0 5px #E9E9E9;">
                    Download
                </a>

                <p class="dica-pix" style="color: #818CBF; font-weight: 300; font-size: 1.4rem;">
                    Faça o Download do QR Code acima e compartilhe com mais pessoas 😉.
                </p>

                <div
                    title="Copiar"
                    class="d-flex align-items-center copy-pix"
                    onclick="window._copyClipBoard(event, 'Chave PIX')"
                    data-target="chave-pix-email">
                    <div>
                        <p class="descr">
                            <span style="font-weight: 500;font-size: .95rem;color: #7883b9;">
                                Chave Pix (Email):
                            </span>
                            <span class="chave-pix">jmpratico.financeiro@hotmail.com</span>
                        </p>
                    </div>
                    <img
                        alt=""
                        width="24"
                        height="24"
                        src="static/img/icon-copy2.svg"
                        style="vertical-align: middle; margin-left: 0.5rem"
                    />
                </div>
  
                <div
                    title="Copiar"
                    style="margin-top: .5rem"
                    class="d-flex align-items-center copy-pix"
                    onclick="window._copyClipBoard(event, 'Copia e Cola PIX')"
                    data-target="chave-pix-copia">
                    <div>
                        <p class="descr">
                            <span style="font-weight: 500;font-size: .95rem;color: #7883b9;">
                                Copie e Cola (Pix):
                            </span>                            
                            <span class="chave-pix">
                                00020126540014BR.GOV.BCB.PIX0132jmpratico.financeiro@hotmail.com5204000053039865802BR5918Jovens Mochileiros6014Pres. Prudente62100506DOACAO6304A754
                            </span>
                        </p>
                    </div>
                    <img
                        alt=""
                        width="24"
                        height="24"
                        src="static/img/icon-copy2.svg"
                        style="vertical-align: middle; margin-left: 0.5rem"
                    />
                </div>
                `,
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            confirmButtonText:
                'Baixar QR Code como imagem',
            customClass: {
                popup: 'modal-donate',
            }
        })

    }
}

export default {
    init,
}